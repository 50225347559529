import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {TextField, Tooltip} from "@mui/material";
import {useReactFlow} from "reactflow";
import {Assignment} from "@mui/icons-material";
import { sanitizeJSONString } from './PipelineBuilderDnDFlow';

export default function NodeInfoDialog({id,nodeData, viewOnly=false}) {
    const {setNodes } = useReactFlow();
    const [open, setOpen] = React.useState(false);
    const [jsonError, setJsonError] = React.useState(false);
    const [configJson, setConfigJson] = React.useState();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setConfigJson(formatConfigJson(nodeData.selected))
        setOpen(false);
    };

    function formatConfigJson(json) {
        if(json && json !== 'undefined') {
            try {
                return JSON.stringify(JSON.parse(sanitizeJSONString(json)), null, 1)
            } catch (err) {
                console.error(err)
            }
        }
        return json;
    }

    function saveJson() {
        try {
            if(configJson && configJson !== 'undefined') {
                setNodes((nodes) => nodes.map(n=> {
                    if(n.id === id) {
                        n.data.selected = configJson 
                        n.data.disabledSelection = true
                    }
                    return n;
                }));
            }
            setJsonError(false);
            setOpen(false);
        }catch (err) {
            console.error(err)
            setJsonError(true)
        }
    }
    const  getDialogTitle = () => {
        let s = nodeData.nodeType
        if(nodeData.selected !== 'undefined') {
            try {
                let v = JSON.parse(sanitizeJSONString(nodeData.selected))
                if(v.name) {
                    return s +" - " + v.name;
                } else if(v.display_name) {
                    return s +" - " + v.display_name;
                } else if(v.ref) {
                    return s +" - " + v.ref;
                }
                return s + " - " + JSON.parse(sanitizeJSONString(nodeData.selected)).name
            }catch(err) {
                console.error("error: "+err+"   \n "+nodeData.selected);
            }
        }
        return s;
    }

    return (
        <React.Fragment>
            <Tooltip title="Edit Configuration">
                <IconButton aria-label="info" size="small" fontSize={"inherit"} sx={{color: '#6551f3', maxHeight: 0.8,m:0,p:0}} onClick={handleClickOpen} disabled={nodeData.selected === 'undefined'}>
                    <Assignment size="small" fontSize="inherit" sx={{maxHeight: 0.8}} color= "primary"></Assignment>
                </IconButton>
            </Tooltip>
            <Dialog onClose={handleClose} aria-labelledby="node-info-dialog-title" open={open}>
                <DialogTitle  id="node-info-dialog-title" fontSize={15} fontWeight={"bold"}>
                    {getDialogTitle()}
                </DialogTitle>
                <IconButton
                    size={"small"}
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 4,
                        top: 4,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers sx={{minWidth:600, minHeight: 300}}>
                    <TextField className={"json-editor"} inputProps={{style: {fontSize: 10}}} onInput={(evt)=>setConfigJson(evt.target.value)}
                               disabled={viewOnly} multiline={true} error={jsonError}  minRows={10} autoFocus value={configJson || formatConfigJson(nodeData.selected)}  variant="outlined"  margin="dense" id={"dialog"+id} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=> {saveJson();}} size={"small"} disabled={viewOnly}>
                        Save
                    </Button>
                    <Button autoFocus onClick={()=> {handleClose();}} size={"small"}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}