import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Link, Stack, TextField, Typography, useTheme} from '@mui/material';
import TransformIcon from "@mui/icons-material/Transform";
import DatasetIcon from "@mui/icons-material/Dataset";
import {Add, Clear, DragIndicator, ExpandMore, SaveAlt} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { sanitizeJSONString } from './PipelineBuilderDnDFlow';
const initialState = {
    sources:[
    ],
    destinations: [

    ],
    transformers: [
    ],
    processors: [
    ],
    staging : [
    ],
    datasets :[

    ],
    sinks: [
    ]
}

const accordionStyles = {
    boxShadow: 'none',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    '&:before': {
        display: 'none',
    }
    
}

export const Sidebar = () => {
    const theme = useTheme();

    const [transformationData, setData] = useState(initialState);
    const [sources,setSources] = useState()
    const [transformers,setTransformers] = useState()
    const [destinations,setDestinations] = useState()
    const [datasets,setDatasets] = useState()
    const [sinks,setSinks] = useState()

    const [deleteDictData,setDeleteDictData] = useState({})
    const [entityDialogOpen, setEntityDialogOpen] = useState(false)
    const [currentEntityType, setCurrentEntityType] = useState("")
    const [newEntityJson, setNewEntityJson] = useState("")
    const [jsonError, setJsonError] = useState(false)
    const [errorMessage,setErrorMessage] = useState("")
    const fetchData = async () => {
        try {
            const response = await fetch(window.serverHost + '/pipeline/transformer/all');
            let result = await response.json();
            let allData = {};
            allData.transformers = [];
            allData.sinks = [];
            allData.datasets = [];
            for (let i = 0; i < result.length; i++) {
                let res = result[i]
                // Munge environment from a list into a dict, with values being each env var's default_value.
                let newEnv = {};
                for (let i = 0; i < res.configuration.environment.length; i++) {
                    let e = res.configuration.environment[i];
                    newEnv[e.name] = e.default_value; 
                }
                res.configuration.environment = newEnv;
                if (res.is_sink && res.is_sink === true) {
                    allData.sinks.push({id:res.id,type:'Sink',config_json:JSON.stringify(res)})
                } else {
                    allData.transformers.push({id:res.id,type:'Transformer',config_json:JSON.stringify(res)})
                }
            }
            allData.transformers.unshift({id:-1,type:'Transformer',config_json:'{"name":"Create New Transformer"}'})
            setTransformers(allData.transformers)
            allData.sinks.unshift({id:-1,type:'Sink',config_json:'{"name":"Create New Sink"}'})
            setSinks(allData.sinks)
            allData.datasets.unshift({id:-1,type:'Dataset',config_json:'{"name":"Create New Dataset"}'})
            setDatasets(allData.datasets)

            setData(allData)
        } catch (error) {
            console.error('Error fetching data:', error);
            setData(initialState)
            setSinks([{id:-1,type:'Sink',config_json:'{"name":"Create New Sink", "is_sink":true}'}]);
            setSources([{id:-1,type:'Source',config_json:'{"name":"Create New Source"}'}])
            setDatasets([{id:-1,type:'Dataset',config_json:'{"name":"Create New Dataset"}'}])
            setDestinations([{id:-1,type:'Destination',config_json:'{"name":"Create New Destination"}'}])
            setTransformers([{id:-1,type:'Transformer',config_json:'{"name":"Create New Transformer"}'}])
        }
    };
    useEffect(() => {
       fetchData();
    }, []);

    const onDragStart = (event, nodeType,selectableData,selectedData) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.setData("selectableData",selectableData)
        event.dataTransfer.setData("selectedData",selectedData)
    };

    function showCreateNew(entity) {
        setCurrentEntityType(entity);
        setEntityDialogOpen(true);
        setNewEntityJson(JSON.stringify({name:entity+" name",display_name:entity+" display_name"},null,4));
    }

    function setDeleteDict(id, value) {
        if(id > 0 ) {
            setDeleteDictData(prev => ({...prev, [id]: value}))
        } else {
            setDeleteDictData(prev => ({...prev, [id]: "none"}))
        }
    }

    function removePredefinedEntity(id) {
        axios.delete(window.serverHost+'/api/v1/pipeline-entity/'+id)
            .then(function (response) {
                fetchData()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function saveEntityJson() {
        setJsonError(false);
        setErrorMessage("");
        try {
            const json = JSON.parse(newEntityJson)
            if(json.name || json.ref) {
                let jData = {type:currentEntityType}
                jData.config_json = JSON.stringify(json)
                axios.post(window.serverHost+'/api/v1/pipeline-entity', jData)
                    .then(function (response) {
                        fetchData();
                        setEntityDialogOpen(false);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                setJsonError(true);
                setErrorMessage("A name or ref field is required!")
            }



        }catch(err) {
            console.log("error =====>"+err)
            setJsonError(true)
            setErrorMessage("Invalid JSON!")
        }
    }

    function handleEntityDialogClose() {
        setEntityDialogOpen(false)
    }

    function getEntityDisplay(config_json) {
        try {
            let v =  JSON.parse(sanitizeJSONString(config_json))
            if(v.display_name) return v.display_name;
            if(v.name) return v.name;
            if(v.ref) return v.ref;
            return "UNKNOWN";
        }catch(err) {
            console.log(err);
            return "<Error>";
        }
    }

    return (
        <aside style={{ borderRadius: '8px', minWidth: '320px', backgroundColor: theme.palette.mode === 'dark' ? '#383838' : '#ddd', borderRight: theme.palette.mode === 'dark' ? '1px solid #2a2a2a' : '1px solid #fff'}}>
            <Typography variant={'h6'}>Pipeline Tools</Typography>
            <Typography variant={'body2'}>Drag and drop to the right to build a data pipeline</Typography>
            <Stack direction={"column"} sx={{marginTop: '8px'}}>
                <div className="dndnode transformer" onDragStart={(event) => onDragStart(event, 'Transformer',JSON.stringify(transformers))} draggable>
                    <DragIndicator />
                    <Typography sx={{width: 150}} fontWeight={700}>
                    <TransformIcon sx={{verticalAlign: "middle"}}/> Transformer</Typography>
                </div>
                <div className="dndnode" onDragStart={(event) => onDragStart(event, 'Dataset',JSON.stringify(datasets))} draggable>
                    <DragIndicator />
                    <Typography sx={{width: 150}} fontWeight={700}>
                    <DatasetIcon sx={{verticalAlign: "middle"}}/> Dataset</Typography>
                </div>
                <div className="dndnode" onDragStart={(event) => onDragStart(event, 'Sink',JSON.stringify(sinks))} draggable>
                    <DragIndicator />
                    <Typography sx={{width: 150}} fontWeight={700}>
                    <SaveAlt sx={{verticalAlign: "middle"}}/> Sink</Typography>
                </div>
            </Stack>
            <Accordion sx={accordionStyles}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                        padding:'3px'
                    }}
                >
                    <Typography variant='subtitle2' sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <TransformIcon />&nbsp;Pre-defined Transformers
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="dndnode" style={{
                        cursor: 'pointer',
                    }} onClick={()=>showCreateNew("Transformer")}>
                        <Add />
                        <Typography flexGrow={1} fontWeight={500} sx={{width: 150}}>Create New Transformer</Typography>
                    </div>
                    {transformationData.transformers.map((transformerData)=>
                        <div className="dndnode entity" onMouseEnter={()=>setDeleteDict(transformerData.id,"block")} onMouseLeave={()=>setDeleteDict(transformerData.id,"none")}
                            onDragStart={(event) => onDragStart(event, 'Transformer',JSON.stringify(transformationData.transformers),transformerData.config_json)}
                            key={transformerData.id + transformerData.name} draggable
                            style={{ whiteSpace: 'normal', paddingRight: '5px' }}
                        >
                            <DragIndicator />
                            <Typography fontSize={11}>
                            <b>{getEntityDisplay(transformerData.config_json)}</b>
                            </Typography>
                            <Link size={"large"} display={deleteDictData[transformerData.id] || "none"} onClick={()=>removePredefinedEntity(transformerData.id)}>
                                <Clear size="large" fontSize="small" sx={{cursor:"pointer",color:"red", paddingLeft:3}}/>
                            </Link>
                        </div>)
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                        padding:'3px'
                    }}
                >
                    <Typography variant='subtitle2' sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <SaveAlt/>&nbsp;Pre-defined Sinks
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="dndnode" style={{
                        cursor: 'pointer',
                    }} onClick={()=>showCreateNew("Sink")}>
                        <Add />
                        <Typography flexGrow={1} fontWeight={500} sx={{width: 150}}>Create New Sink</Typography>
                    </div>
                    {transformationData.sinks.map((sinkData)=>
                        <div className="dndnode entity" onMouseEnter={()=>setDeleteDict(sinkData.id,"block")} onMouseLeave={()=>setDeleteDict(sinkData.id,"none")}
                            onDragStart={(event) => onDragStart(event, 'Sink',JSON.stringify(transformationData.sinks),sinkData.config_json)}
                            key={sinkData.id + sinkData.name} draggable
                            style={{ whiteSpace: 'normal', paddingRight: '5px' }}
                        >
                            <DragIndicator />
                            <Typography fontSize={11}>
                            <b>{getEntityDisplay(sinkData.config_json)}</b>
                            </Typography>
                            <Link size={"large"} display={deleteDictData[sinkData.id] || "none"} onClick={()=>removePredefinedEntity(sinkData.id)}>
                                <Clear size="large" fontSize="small" sx={{cursor:"pointer",color:"red", paddingLeft:3}}/>
                            </Link>
                        </div>)
                    }
                </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                        padding:'3px'
                    }}
                >
                    <Typography variant='subtitle2' sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <DatasetIcon/>&nbsp;Pre-defined Datasets
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="dndnode" style={{
                        cursor: 'pointer',
                    }} onClick={()=>showCreateNew("Dataset")}>
                        <Add />
                        <Typography flexGrow={1} fontWeight={500} sx={{width: 150}}>Create New Dataset</Typography>
                    </div>
                    {transformationData.datasets.map((datasetData)=>
                        <div className="dndnode entity" onMouseEnter={()=>setDeleteDict(datasetData.id,"block")}
                            onMouseLeave={()=>setDeleteDict(datasetData.id,"none")}
                            onDragStart={(event) => onDragStart(event, 'Dataset',JSON.stringify(transformationData.datasets),datasetData.config_json)}
                            key={datasetData.id} draggable
                            style={{ whiteSpace: 'normal', paddingRight: '5px' }}
                        >
                            <DragIndicator />
                            <Typography fontSize={11}>
                            <b>{getEntityDisplay(datasetData.config_json)}</b>
                            </Typography>
                            <Link size={"large"} display={deleteDictData[datasetData.id] || "none"} onClick={()=>removePredefinedEntity(datasetData.id)}>
                                <Clear size="large" fontSize="small" sx={{cursor:"pointer",color:"red", paddingLeft:3}}/>
                            </Link>
                        </div>)
                    }
                </AccordionDetails>
            </Accordion>
            <Dialog onClose={handleEntityDialogClose} aria-labelledby="node-info-dialog-title" open={entityDialogOpen}>
                <DialogTitle  id="node-info-dialog-title" fontSize={15} fontWeight={"bold"}>
                    Create new Pre-defined {currentEntityType}
                </DialogTitle>
                <IconButton
                    size={"small"}
                    aria-label="close"
                    onClick={handleEntityDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 4,
                        top: 4,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <DialogContent dividers sx={{minWidth:600, minHeight: 300}}>
                    <label style={{color:"red", fontSize:10}}>{errorMessage}</label>
                    <TextField className={"json-editor"} inputProps={{style: {fontSize: 10}}} onInput={(evt)=>setNewEntityJson(evt.target.value)}
                               multiline={true} error={jsonError}  minRows={10} autoFocus value={newEntityJson}  variant="outlined"  margin="dense" id={"newEntityDialogId"} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=> {saveEntityJson();}} size={"small"} sx={{ color: '#6551f3' }}>
                        Save
                    </Button>
                    <Button autoFocus onClick={()=> {handleEntityDialogClose();}} size={"small"} sx={{ color: '#6551f3' }}>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>

        </aside>
    );
};
