import React, {memo, useCallback} from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import Select from '@mui/material/Select';
import {FormControl, IconButton, MenuItem, Stack, Tooltip, Typography, useTheme} from '@mui/material';
import OutputIcon from '@mui/icons-material/Output';
import DeleteIcon from '@mui/icons-material/Delete';
import TransformIcon from "@mui/icons-material/Transform";
import RepeatIcon from "@mui/icons-material/Repeat";
import InputIcon from "@mui/icons-material/Input";
import MediationIcon from "@mui/icons-material/Mediation";
import NodeInfoDialog from "./NodeInfoDialog";
import DatasetIcon from "@mui/icons-material/Dataset";
import {SaveAlt} from "@mui/icons-material";
import { sanitizeJSONString } from './PipelineBuilderDnDFlow';

function DataSelect({ value, selectable, nodeId, disabledSelection,nodeType }) {
    const theme = useTheme();
    const {  setNodes } = useReactFlow();
    const store = useStoreApi();
    const onChange = (evt) => {
        const { nodeInternals } = store.getState();
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        selected: evt.target.value
                    }
                }
                return node;
            })
        );
    };
    const getMenuItemDisplay = (config_json) => {
        let jdata = JSON.parse(sanitizeJSONString(config_json));
        if (jdata.display_name) {
            return jdata.display_name
        } else if(jdata.ref) {
            return jdata.ref
        } else if(jdata.name) {
            return jdata.name
        }else {
            return "UNKNOWN"
        }
    }
    const renderSelect = () => {
        const darkMode = theme.palette.mode === 'dark';

        if(disabledSelection) {
            return <Select
              className="nodrag select-field-size"
              onChange={onChange} value={value}
              disabled={disabledSelection}
              sx={{
                  backgroundColor: darkMode ? '#121212' : '#fff',
                  color: darkMode ? '#fff' : '#000',
              }}
            >
                <MenuItem key={nodeId} value={value}>
                    {getMenuItemDisplay(value)}
                </MenuItem>
            </Select>
        } else {
            return <Select
              className="nodrag select-field-size"
              onChange={onChange} value={value}
              disabled={disabledSelection}
              sx={{
                  backgroundColor: darkMode ? '#121212' : '#fff',
                  color: darkMode ? '#fff' : '#000',
              }}
            >
                {selectable.map((option,key) => (
                    <MenuItem key={key} value={option.config_json}>
                        {getMenuItemDisplay(option.config_json)}
                    </MenuItem>
                ))}
            </Select>
        }
    }
    return (
        <div className="node__select">
            <FormControl size="small" fullWidth>
                {renderSelect()}
            </FormControl>
            {renderHandle(nodeType,nodeId)}
        </div>
    );
}
function renderHandle(nodeType,handleId) {
    if(nodeType === 'Source') {
        return <Handle type="source" position={Position.Right} id={handleId} />
    } else if(nodeType === 'Transformer') {
        return  <div><Handle type="source" position={Position.Right} id={handleId} />
        <Handle type="target" position={Position.Left} id={handleId} isConnectable="true" />
        </div>
    } else if(nodeType === 'Staging') {
        return  <div><Handle type="Source" position={Position.Right} id={handleId} />
            <Handle type="target" position={Position.Left} id={handleId} /></div>
    } else if(nodeType === 'Destination') {
        // return  <Handle type="target" position={Position.Left} id={handleId} />
        return  <div><Handle type="source" position={Position.Right} id={handleId} />
            <Handle type="target" position={Position.Left} id={handleId} isConnectable="true" />
        </div>
    } else if(nodeType === 'Processor') {
        return <div><Handle type="source" position={Position.Right} id={handleId} />
            <Handle type="target" position={Position.Left} id={handleId} isConnectable="true" />
        </div>
    }
    else if(nodeType === 'Dataset') {
        // return  <Handle type="target" position={Position.Left} id={handleId} />
        return  <div><Handle type="source" position={Position.Right} id={handleId} />
            <Handle type="target" position={Position.Left} id={handleId} isConnectable="true" />
        </div>
    }
    else if(nodeType === 'Sink') {
        // return  <Handle type="target" position={Position.Left} id={handleId} />
        return <Handle type="target" position={Position.Left} id={handleId} isConnectable="true" />
    }
    return <div/>
}
function renderHeader(data) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    // const iconColor = theme.palette.mode === 'dark' ? '#6551f3' : '#38354a';
    // const headerTextColor = theme.palette.mode === 'dark' ? '#b2c3ff' : '#6551f3';
    const iconColor = '#7A66C8';
    const headerTextColor = '#7A66C8';

    if(data.nodeType === 'Source') {
        return  <Typography variant={"subtitle3"}><InputIcon sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Data Source</b>:<b style={{ color: headerTextColor }}>  Select a Data Source</b>}
        </Typography>;
    } else if(data.nodeType === 'Transformer') {
        return  <Typography variant={"subtitle3"}><TransformIcon sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Transformer</b>:<b style={{ color: headerTextColor }}>  Select a Data Transformer</b>}
        </Typography>
    } else if(data.nodeType === 'Staging') {
        return  <Typography variant={"subtitle3"}><RepeatIcon sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Staging</b>:<b style={{ color: headerTextColor }}>  Select a Data Staging</b>}
        </Typography>
    } else if(data.nodeType === 'Destination') {
        return  <Typography variant={"subtitle3"}><OutputIcon sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Destination</b>:<b style={{ color: headerTextColor }}>  Select a Destination</b>}
        </Typography>
    } else if(data.nodeType === 'Processor') {
        return  <Typography variant={"subtitle3"}>< MediationIcon sx={{color: iconColor, verticalAlign:"middle"}}/> <b>Select a Processor</b></Typography>
    } else if(data.nodeType === 'Dataset') {
        return  <Typography variant={"subtitle3"}><DatasetIcon sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Dataset</b>:<b style={{ color: headerTextColor }}>  Select a Dataset</b>}
        </Typography>
    } else if(data.nodeType === 'Sink') {
        return  <Typography variant={"subtitle3"}><SaveAlt sx={{color: iconColor, verticalAlign:"middle"} }/>
            {data.disabledSelection ? <b style={{ color: headerTextColor }}>   Data Sink</b>:<b style={{ color: headerTextColor }}>  Select a Data Sink</b>}
        </Typography>
    }
    return <div/>
}
function DataNode({ id, data }) {
    const {setNodes, setEdges } = useReactFlow();
    const theme = useTheme();
    const backgroundColor = theme.palette.mode === 'dark' ? '#020310' : '#fff';
    const store = useStoreApi();
    const deleteNode = useCallback(() => {
        setNodes((nodes) => nodes.filter((node) => node.id !== id));
        setEdges((edges) => edges.filter((edge) => edge.source !== id));
    }, [id, setNodes, setEdges]);
    return (
        <div
          className="node-box"
          style={{
              backgroundColor: backgroundColor,
          }}
        >
            <div className="node__header">
                {renderHeader(data)}
            </div>
            <div className="node__body">
                <DataSelect key={id+"_select"} nodeId={id} value={data.selected} selectable={data.selectable} disabledSelection={data.disabledSelection} nodeType={data.nodeType}/>
            </div>
            <div className="node__footer">
                <Stack direction="row" spacing={0.5}>
                    <Tooltip title={"Click for more information"}>
                        <NodeInfoDialog nodeData={data} id={id} viewOnly={data.viewOnly}/>
                    </Tooltip>
                    <Tooltip title="Remove this node">
                        <IconButton aria-label="delete" size="small" sx={{maxHeight: 0.8,m: 0,p:0}} onClick={deleteNode} disabled={data.viewOnly}>
                            <DeleteIcon size="small" fontSize="inherit" sx={{maxHeight: 0.8}}></DeleteIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </div>
        </div>
    );
}

export default memo(DataNode);