import React, {useState, useRef, useCallback, useEffect} from 'react';
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    useNodesState,
    useEdgesState,
    Controls, Panel, MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';

import { Sidebar } from './Sidebar';

import './index.css';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Stack,
    TextField,
    Tooltip,
    useTheme
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { v4 as uuidv4 } from 'uuid';
import DataNode from "./DataNode";
import {useNavigate, useParams} from 'react-router-dom';
import axios from "axios";
import {RunCircle, StopCircle, ViewAgenda} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";


// Replace control characters with '' in a JSON string.
export const sanitizeJSONString = (input) =>
    input.replace(/[\n\r\t]/g, '');

const nodeTypes = {
    Transformer: DataNode,
    Source: DataNode,
    Destination: DataNode,
    Processor: DataNode,
    Staging: DataNode,
    Dataset: DataNode,
    Sink: DataNode,
};
const getId = () => uuidv4()
const getPipeline = (flow,title) => {
    let pipeline = [];  // this is the list of pipeline vertex ids.
    let transformerMap = {};  //the transformer map to get access to transformer data by pipeline node (vertex)'s id
    // the key is the vertex unique id, the value is configuration data

    let pipelineNodeMap = {}; // the pipeline node map. This could be sink, dataset,transformer ... etc ...
    // anything that is a vertex on the pipeline
    // the key is the vertex unique id, the value is the configuration data
    let indexMap = {}
    let idex = 0
    flow.nodes.forEach(n=> {
        pipelineNodeMap[n.id] = n.data.selected;
        if(n.type === 'Transformer') {
            pipeline.push(n.id);
            transformerMap[n.id] = n.data.selected;
            indexMap[n.id] = idex;
            idex ++;
        } else if (n.data.selected && JSON.parse(sanitizeJSONString(n.data.selected)).is_sink === true) {
            pipeline.push(n.id)
            transformerMap[n.id] = n.data.selected;
            indexMap[n.id] = idex;
            idex ++;
        }
    })
    let pipelineS = pipeline.map((id, index)=> {
        let transformer = JSON.parse(sanitizeJSONString(transformerMap[id]));
        let outputDict = {};
        let inputDict = {};
        for(let i = 0;i < flow.edges.length;i++){
            if(flow.edges[i].source === id) { //if this transformer is a source, then output is a destination
                let destinationId = flow.edges[i].target
                //this destinationId could also be a sourceId going into another transformer.
                if(pipelineNodeMap[destinationId] && pipelineNodeMap[destinationId] !== 'undefined') {
                    let vertexConfigData = JSON.parse(sanitizeJSONString(pipelineNodeMap[destinationId]));
                    //we do need ref from the vertex config data
                    let ref = {
                        ref: vertexConfigData.ref
                    }
                    if (transformer.outputs) {
                        let i = 0;
                        for (let key in transformer.outputs) {
                            if (outputDict[destinationId]) {
                                console.log("output: " + key + " of " + destinationId + " has already been processed")
                            }
                            else {
                                outputDict[destinationId] = i;
                                //combining transformer's output config with vertexConfigData
                                transformer.outputs[key] = {...transformer.outputs[key], ...ref}
                                // If the user doesn't override the conn_type, make it a dataset
                                let conn_type = transformer.outputs[key].conn_type
                                //if conn_type is not set in the transformer template, set it to be dataset
                                if (!conn_type || "" === conn_type) {
                                    transformer.outputs[key].conn_type = 'DATASET';
                                }
                            }
                            i++;
                        }
                    }
                }
            } else if(flow.edges[i].target === id) { //if this transformer is a target, then input is a source
                let sourceId = flow.edges[i].source
                if(pipelineNodeMap[sourceId] && pipelineNodeMap[sourceId] !=='undefined') {
                    //note that this sourceId could also be a targetId of another node
                    let vertexConfigData = JSON.parse(sanitizeJSONString(pipelineNodeMap[sourceId]));
                    let ref = {
                        ref: vertexConfigData.ref
                    }
                    if (transformer.inputs) {
                        let i = 0;
                        for (let key in transformer.inputs) {
                            if (inputDict[sourceId]) {} else {
                                inputDict[sourceId] = i;
                                //combining inputs with vertexConfig data.
                                transformer.inputs[key] = {...transformer.inputs[key], ...ref}
                                // If the user doesn't override the conn_type, make it a dataset
                                let conn_type = transformer.inputs[key].conn_type
                                if (!conn_type || "" === conn_type) {
                                    transformer.inputs[key].conn_type = 'DATASET';
                                }
                                //inputs already set, what is the output for this
                                if (transformer.inputs[key].conn_type === 'TRANSFORMER')
                                {
                                    if (indexMap[sourceId] != undefined) {
                                        transformer.inputs[key].stage = indexMap[sourceId]
                                    }
                                    let outputKey = ''
                                    Object.keys(vertexConfigData.outputs).forEach((x) => {
                                        if(vertexConfigData.outputs[x].conn_type === 'TRANSFORMER') {
                                            outputKey = x;
                                        }
                                    })
                                    if (outputKey !== '') {
                                        transformer.inputs[key].key = outputKey
                                    }
                                }
                            }
                            i++;
                        }
                    }
                }
            }
        }
        //if an entity is an output of a transformer and becomes an input of another transformer
        //that is identified by "ref" entity, we need to adjust the input part to be the source

        return transformer
    })
    return {name:title,pipeline:pipelineS};
}

const PipelineBuilderDnDFlow = (callback, deps) => {
    const {id} = useParams();
    const reactFlowWrapper = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [pipelineName,setPipelineName] = React.useState("")
    const [pipelineStatus, setPipelineStatus] = React.useState("")
    const [viewPipelineOpen, setViewPipelineOpen] = React.useState(false);
    const [pipeline, setPipeline] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    let navigate = useNavigate();
    const theme = useTheme();


    const fetchData = async () => {
        try {
            if (id !== 'new') {
                const response = await fetch(window.serverHost+'/pipeline/instance/'+id);
                const result = await response.json();
                setPipelineName(result.name)
                setPipelineStatus(result.state)
                const flow = JSON.parse(result.layout)
                if(flow) {
                    if (flow.edges) {
                        for (let i = 0; i< flow.edges.length; i++) {
                            flow.edges[i].animated = (result.state === 'active')
                        }
                    }
                    if(flow.nodes) {
                        for (let i = 0; i< flow.nodes.length; i++) {
                            console.dir(flow.nodes[i])
                            flow.nodes[i].data.viewOnly = (result.state === 'active')
                        }
                    }
                    setNodes(flow.nodes || [])
                    setEdges(flow.edges || [])
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchData();
    },[]); //do not specify fetch data as a dependency. and do not remove the empty array. We'd want it to be called once. It'd cause fetch data to be called on
    //every render
    const saveFlow = useCallback((params)=> {
        var err = false;
        if(reactFlowInstance) {
            if(pipelineName && pipelineName !== '') {
                setIsSaving(true);
                const flow = reactFlowInstance.toObject();
                var pipelineS = {};
                try {
                    pipelineS = getPipeline(flow,pipelineName);
                } catch (e) {
                    alert('Could not validate pipeline graph.');
                    return;
                };
                const flowS = JSON.stringify(flow);
                if(id !== 'new') {
                    err = false;
                    axios.put(window.serverHost+'/pipeline/instance/'+id, {
                        name: pipelineName,
                        layout: flowS,
                        pipeline: pipelineS.pipeline,
                    })
                        .then((response) => response.data)
                        .catch(function (error) {
                            err = true;
                            setIsSaving(false);
                            alert('Failed to save pipeline:\n\n' + error.response.data);
                        })
                        .then(function (response) {
                            if (!err) {
                                setIsSaving(false);
                                if (params.nav_back) {
                                    navigate("/")
                                }
                            }
                        });
                } else {
                    err = false;
                    axios.post(window.serverHost+'/pipeline/instance/create?instantiate=false', {
                        name: pipelineName,
                        layout: flowS,
                        pipeline: pipelineS.pipeline,
                    })
                        .then((response) => response.data)
                        .catch(function (error) {
                            err = true;
                            setIsSaving(false);
                            alert('Failed to create new pipeline: \n\n' + error.response.data);
                        })
                        .then(function (response) {
                            setIsSaving(false);
                            if (!err) {
                                navigate("/")
                            }
                        });
                }
            }
        }
    },[reactFlowInstance,pipelineName,id,navigate],);
    const stopPipeline = useCallback((params)=> {
        setIsSaving(true);
        axios.post(window.serverHost+'/pipeline/instance/'+id+'/stop')
            .then(function (response) {
                setIsSaving(false);
                fetchData();
            })
            .catch(function (error) {
                setIsSaving(false);
                alert(error + ':\n\n' + error.response.data)
                console.log(error);
            });
    },[id,navigate,saveFlow]);
    const executePipeline = useCallback((params)=> {
        saveFlow({nav_back: false});
        const flow = reactFlowInstance.toObject();
        const pipelineS = getPipeline(flow,pipelineName);
        const flowS = JSON.stringify(flow);
        if(id !== 'new') {
            var err = false;
            setIsSaving(true);
            axios.put(window.serverHost+'/pipeline/instance/'+id+'?instantiate=true', {
                name: pipelineName,
                layout: flowS,
                pipeline: pipelineS.pipeline,
            })
                .then((response) => response.data)
                .catch(function (error) {
                    err = true;
                    setIsSaving(false);
                    alert('Failed to start pipeline:\n\n' + error.response.data);
                })
                .then(function (response) {
                    setIsSaving(false);
                    if (!err) {
                        //navigate("/")
                        fetchData();
                    }
                });
        } else {
            err = false;
            axios.post(window.serverHost+'/pipeline/instance/create?instantiate=true', {
                name: pipelineName,
                layout: flowS,
                pipeline: pipelineS.pipeline,
            })
                .then((response) => response.data)
                .catch(function (error) {
                    err = true;
                    setIsSaving(false);
                    console.log(error);
                    alert('Failed to create pipeline:\n\n' + error.response.data);
                })
                .then(function (response) {
                    setIsSaving(false);
                    if (!err) {
                        navigate("/")
                    }
                });
        }
    },[id,navigate,saveFlow]);

    const onConnect = useCallback(
        (params) =>  {
            setEdges((eds) => addEdge(params, eds));

        },
        [setEdges],
    );

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event) => {
            event.preventDefault();

            const type = event.dataTransfer.getData('application/reactflow');
            const selectableData = event.dataTransfer.getData('selectableData');
            const selectedData = event.dataTransfer.getData("selectedData");
            let selectableObj = {};
            let predefined = !(selectableData === 'undefined')
            if (predefined === true) {
                selectableObj = JSON.parse(selectableData)
            }
            // check if the dropped element is valid
            if (typeof type === 'undefined' || !type) {
                return;
            }
            // reactFlowInstance.project was renamed to reactFlowInstance.screenToFlowPosition
            // and you don't need to subtract the reactFlowBounds.left/top anymore
            // details: https://reactflow.dev/whats-new/2023-11-10
            const position = reactFlowInstance.screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });
            let node  = {
                id: getId(),
                type: type,
                position: position,
                data : {
                    nodeType: type,
                    selectable: selectableObj,
                    selected: selectedData,
                    disabledSelection: selectedData !== 'undefined',
                    viewOnly: pipelineStatus === 'active'
                }
            }
            setNodes((nds) => nds.concat(node));
        },
        [reactFlowInstance,setNodes],
    );
    const openPipelineViewer = useCallback((params) => {
        if(reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            const pipelineS = getPipeline(flow,pipelineName);
            setPipeline(pipelineS)
        }
        setViewPipelineOpen(true)
    },[reactFlowInstance,pipelineName])


    return (
        <div className="dndflow">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isSaving}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ReactFlowProvider>
                <Box margin={'32px 0px 0px 32px'} width={'min-content'}>
                    <Sidebar />
                    <Controls />

                </Box>
                <Panel position="top-right" className={"top-pipeline-container"}>
                    <TextField
                        sx={{ color: '#6551f3' }}
                        error={!pipelineName || pipelineName === ""}
                        autoFocus
                        margin="dense"
                        id="pipeline-name"
                        label="Data Pipeline Name"
                        fullWidth
                        variant="standard"
                        value={pipelineName}
                        onInput={e=>setPipelineName(e.target.value)}
                    />
                </Panel>
                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onInit={setReactFlowInstance}
                        onDrop={onDrop}
                        onDragOver={onDragOver}
                        nodeTypes={nodeTypes}
                        nodesConnectable={pipelineStatus !== 'active'}
                        defaultEdgeOptions={{markerEnd: { type: MarkerType.ArrowClosed,
                                width: 20,
                                height: 30,
                            }}}
                        fitViewOptions={{maxZoom:1.3}}
                        fitView={true}
                    >
                        <Panel position="bottom-right">
                            <Stack spacing={1} direction={"row"} padding={1}>
                                <React.Fragment>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        startIcon={<ViewAgenda/>}
                                        onClick={() => openPipelineViewer()}
                                        sx={{
                                            color: '#6551f3',
                                            '&:hover': {
                                                borderColor: '#6551f3', // Set the desired purple color
                                            },
                                        }}
                                    >
                                        View Pipeline Definition
                                    </Button>
                                    <Dialog open={viewPipelineOpen} onClose={()=>setViewPipelineOpen(false)}>
                                        <DialogTitle>Pipeline Definition</DialogTitle>
                                        <DialogContent>
                                            <DialogContent dividers sx={{minWidth:600, maxHeight: 500}}>
                                                <TextField className={"json-editor"} inputProps={{style: {fontSize: 12, fontColor:"red"}}}
                                                           multiline={true} minRows={5}  value={JSON.stringify(pipeline,null,4)}  variant="standard"  margin="dense" id={"dialog"+id} />
                                            </DialogContent>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={()=>setViewPipelineOpen(false)}>Close</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                                <Tooltip title={pipelineStatus === 'active' ? "Pipeline is executing. No change can be saved until it is stop": "Save Pipeline"}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        disabled={pipelineStatus === 'active'}
                                        startIcon={<SaveIcon/>}
                                        onClick={() => saveFlow({nav_back: true})}
                                        sx={{
                                            color: '#6551f3',
                                            '&:hover': {
                                                borderColor: '#6551f3', // Set the desired purple color
                                            },
                                        }}
                                    >
                                        Save Pipeline
                                    </Button>
                                </Tooltip>
                                <Tooltip title={pipelineStatus === 'active' ? "Pipeline is executing. No execution until it is stopped.": "Execute Pipeline"}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        endIcon={pipelineStatus === 'active'?<RunCircle/> : <SendIcon/>}
                                        disabled={id === 'new' || pipelineStatus === 'active'}
                                        onClick={executePipeline}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#b2c3ff' : '#6551f3',
                                            backgroundColor: theme.palette.mode === 'dark' ? '#6551f3' : '#b2c3ff',
                                            '&:hover': {
                                                backgroundColor: theme.palette.mode === 'dark' ? '#282061' : '#C1CFFF',
                                            },
                                        }}
                                    >
                                        Execute Pipeline
                                    </Button>
                                </Tooltip>
                                <Tooltip title={pipelineStatus === 'active' ? "Stop Pipeline Execution": "Stop Pipeline"}>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        disabled={pipelineStatus !== 'active'}
                                        endIcon={<StopCircle/>}
                                        onClick={stopPipeline}
                                        sx={{
                                            color: theme.palette.mode === 'dark' ? '#b2c3ff' : '#6551f3',
                                            backgroundColor: theme.palette.mode === 'dark' ? '#6551f3' : '#b2c3ff',
                                            '&:hover': {
                                                backgroundColor: theme.palette.mode === 'dark' ? '#282061' : '#C1CFFF',
                                            },
                                        }}
                                    >
                                        Stop Pipeline
                                    </Button>
                                </Tooltip>
                            </Stack>
                        </Panel>
                    </ReactFlow>
                </div>
            </ReactFlowProvider>
        </div>
    );
};

export default PipelineBuilderDnDFlow;
