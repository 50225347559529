import PipelineBuilderDnDFlow from "./PipelineBuilderDnDFlow";
import PipelineList from "./PipelineList";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AppBar, Toolbar, Tooltip, CssBaseline
} from '@mui/material';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListIcon from '@mui/icons-material/List';
import CreateIcon from '@mui/icons-material/AddCircle';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import LightModeIcon from '@mui/icons-material/LightMode';
import * as React from 'react';
import { ThemeContext } from "./contexts/ThemeProvider";

window.serverHost=(process.env.NODE_ENV === 'development') ? "http://localhost:8080" : "";

export default function App() {
  const { darkMode, toggleDarkMode } = React.useContext(ThemeContext);

  return (
    <>
      <CssBaseline />
      <div className={`main-container ${darkMode ? 'dark-mode' : ''}`} >
        <AppBar position="fixed" sx={{ backgroundColor: darkMode ? '#020310' : '#6551f3' }}>
          <Toolbar>
            <Typography
              variant="h5"
              noWrap
              sx={{
                flexGrow: 1,
              }}
            >
              Data Fabric Data Pipelines
            </Typography>
            <Tooltip title="Show all existing pipelines">
              <IconButton href="/" color="inherit">
                <ListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Create a new Data Pipeline">
              <IconButton href="/pipeline/new" color="inherit">
                <CreateIcon />
              </IconButton>
            </Tooltip>
            {/*<div>*/}
            {/*  <IconButton*/}
            {/*    onClick={() => {*/}
            {/*      toggleDarkMode();*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {darkMode ? <LightModeIcon /> : <NightsStayIcon />}*/}
            {/*  </IconButton>*/}
            {/*</div>*/}
          </Toolbar>
        </AppBar>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<PipelineList />} />
              <Route path="/pipeline/:id" element={<PipelineBuilderDnDFlow />} />
            </Routes>
          </BrowserRouter>
      </div>
      </>
  );
}
