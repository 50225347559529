import React, {memo, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {
    Backdrop,
    Button, CircularProgress,
    DialogContentText, LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from '@mui/material';
import {styled} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {PlayArrow, Stop} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    '& .MuiTableCell-head': {
        color: 'white',
        backgroundColor: theme.palette.mode === 'dark' ? '#100a31' : '#221f36',
    },
}));
// <TableHead sx={{backgroundColor: theme.palette.mode === 'dark' ? '#100a31' : '#221f36'}}>


function PipelineList({id}) {
    const [data, setData] = useState([]);
    const [fetchErr, setFetchErr] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [open,setOpen] = useState(false)
    const[confirmationDialogTitle,setConfirmationDialogTitle]= useState("")
    const[confirmationDialogText,setConfirmationDialogText]= useState("")
    const [currentId,setCurrentId] = useState(-1)
    const [actionType,setActionType] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const fetchData = async () => {
        setDataLoading(true);
        try {
            const response = await fetch(window.serverHost+'/pipeline/instance/all');
            const result = await response.json();
            //sort by name so the display won't be jumpy
            result.sort((a,b) => (a && a.name && b && b.name)? a.name.localeCompare(b.name) : -1)
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (!error.response) {
                setFetchErr('Network Error')
            } else {
                setFetchErr(error + ':\n\n' + error.response);
            }
        }
        setDataLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);
    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#5a5e5a',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    if (fetchErr !== '') return <div className={"list-pipeline-container"}>
            <Typography>Error loading pipelines: {fetchErr}</Typography>
        </div>


    if (dataLoading) return <div className={"list-pipeline-container"}>
            <Typography>Loading...</Typography>
        </div>
    else if (!data || data.length === 0) {
        return <div className={"list-pipeline-container"}>
            <Typography>There are no pipelines currently.&nbsp;
                <Link href={"/pipeline/new"} underline="hover" sx={{ color: '#6551f3' }}>Create a new Pipeline here.</Link>
            </Typography>
        </div>
    }

    function displayDateTime(datetime) {
        if(datetime && datetime !== '') {
            let d = new Date(datetime);
            if(d.getFullYear() === 1 || d.getMilliseconds() === 0) {
                return ''
            }
            return d.toLocaleString("en-US");
        }
        return '';
    }
    function openDialog(title,content,id,actionType) {
        setConfirmationDialogTitle(title);
        setConfirmationDialogText(content);
        setCurrentId(id);
        setActionType(actionType)
        setOpen(true);
    }
    function executeIt() {
        if(actionType === 'delete') {
            deletePipeline(currentId)
        } else if(actionType === 'execute') {
            executePipeline(currentId)
        } else if(actionType === 'stop') {
            stopPipeline(currentId)
        }
        setOpen(false);
    }
    function handleClose() {
        setOpen(false);
    }
    const deletePipeline = (id) =>{
        setIsSaving(true)
        axios.delete(window.serverHost+'/pipeline/instance/'+id)
            .then(function (response) {
                setIsSaving(false);
                fetchData()
            })
            .catch(function (error) {
                setIsSaving(false);
                alert(error + ':\n\n' + error.response.data)
                console.log(error);
            });
    }
    const executePipeline = (id) =>{
        setIsSaving(true);
        axios.post(window.serverHost+'/pipeline/instance/'+id+'/start')
            .then(function (response) {
                setIsSaving(false);
                fetchData();
            })
            .catch(function (error) {
                setIsSaving(false);
                alert(error + ':\n\n' + error.response.data)
                console.log(error);
            });
    }
    const stopPipeline = (id) =>{
        setIsSaving(true);
        axios.post(window.serverHost+'/pipeline/instance/'+id+'/stop')
            .then(function (response) {
                setIsSaving(false);
                fetchData();
            })
            .catch(function (error) {
                setIsSaving(false);
                alert(error + ':\n\n' + error.response.data)
                console.log(error);
            });
    }

    return  <div className={"list-pipeline-container"}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSaving}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <StyledTableHead>
                    {/* eslint-disable-next-line no-undef */}
                    <TableRow>
                        <StyledTableCell align="center" >Id</StyledTableCell>
                        <StyledTableCell align="left">Name</StyledTableCell>
                        <StyledTableCell align="center">Last Start Date Time</StyledTableCell>
                        <StyledTableCell align="center">Last Stop Date Time</StyledTableCell>
                        <StyledTableCell align="center">Status</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                </StyledTableHead>
                <TableBody>
                    {data.map((entry, index) => (
                        <StyledTableRow key={entry.id}>
                            <StyledTableCell align="center">{entry.id}</StyledTableCell>
                            <StyledTableCell align="left">
                                <Link href={'pipeline/' + entry.id + ''}>
                                    {entry.name}
                                </Link>
                            </StyledTableCell>
                            <StyledTableCell align="center">{displayDateTime(entry.last_started)}</StyledTableCell>
                            <StyledTableCell align="center">{displayDateTime(entry.last_stopped)}</StyledTableCell>
                            <StyledTableCell align="center">
                                {entry.state === 'active' ? <LinearProgress variant="indeterminate" /> : <span/> }
                                {entry.state}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <Tooltip title={"Execute this "+entry.name+" pipeline"}>
                                    <IconButton disabled={entry.state === 'active'}  onClick={()=> openDialog("Execute Pipeline","You are about to execute this pipeline. Continue?",entry.id,"execute")}>
                                        <PlayArrow/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Stop this "+entry.name+" pipeline"}>
                                    <IconButton  disabled={entry.state !== 'active'} onClick={()=> openDialog("Stop Pipeline","You are about to stop this pipeline. Continue?",entry.id,"stop")}>
                                        <Stop/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete this "+entry.name+" pipeline"}>
                                    <IconButton onClick={()=> openDialog("Delete Pipeline","You are about to delete a pipeline. Continue?",entry.id,"delete")}>
                                        <DeleteIcon></DeleteIcon>
                                    </IconButton>
                                </Tooltip>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog
            open={open}
            onClose={()=>handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {confirmationDialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirmationDialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={executeIt} autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    </div>
}
export default memo(PipelineList);